<template>
  <div class="p-grid crud-demo">
    <Loader v-model="loading" />
    <div class="p-col-12">
      <div class="card">
        <Toast />
        <Panel header="Control Vehicular">
          <BasicFormToolbar v-if="!view" @new="openNew" @save="save" @list="list" :actions="['new','save','list']" />
          <div class="grid formgrid p-fluid">
            <div class="col-12">
              <TabView ref="tab">
              <TabPanel header="Datos Generales">
                <Fieldset :toggleable="true" legend="Información Configuración Vehicular">
                  <div class="p-fluid formgrid grid">
                    <FormDropdownComplex :labelInOption="'c_TipoPermiso - Descripcion'" :labelInValue="'c_TipoPermiso - Descripcion'" :wrapperClass="'field col-6'" label="Permiso SCT" v-model="entity.permit_sct_sat" :options="c_TipoPermiso" optionLabel="c_TipoPermiso" optionValue="c_TipoPermiso" :tooltip="'Se obtiene del catalogo SAT c_TipoPermiso'"/>
                    <FormInputText wrapperClass="field col-4" label="Número de Permiso" :valid="validate.validations.num_permit_sct" v-model="entity.num_permit_sct" />
                    <FormDropdownComplex :labelInOption="'c_ConfigAutotransporte - Descripcion'" :labelInValue="'c_ConfigAutotransporte - Descripcion'" :wrapperClass="'field col-6'" label="Configuración Vehicular" v-model="entity.vehicle_config_sat_code" :options="c_ConfigAutotransporte" optionLabel="c_ConfigAutotransporte" optionValue="c_ConfigAutotransporte" :tooltip="'Se obtiene del catalogo SAT c_ConfigAutotransporte'"/>
                    <FormInputText wrapperClass="field col-4" label="Placa VM" :valid="validate.validations.license_plate_vm" v-model="entity.license_plate_vm" :tooltip="'Ingresar la placa del vehiculo motor, sin guiones ni espacios'"/>
                    <FormInputText wrapperClass="field col-4" label="Año VM" :valid="validate.validations.anio_vm" v-model="entity.anio_vm" />
                    <FormInputNumber wrapperClass="field col-4" label="Valor Prima Seguro" :valid="validate.validations.premium_insurance_value" :mode="'currency'" v-model="entity.premium_insurance_value" />
                  </div>
                </Fieldset>
              </TabPanel>
              <TabPanel header="Aseguradoras">
                <Fieldset :toggleable="true" legend="Información Aseguradora Civil">
                  <div class="p-fluid formgrid grid">
                    <FormInputText wrapperClass="field col-4" label="Nombre" :valid="validate.validations.civil_insurer" v-model="entity.civil_insurer" />
                    <FormInputText wrapperClass="field col-4" label="Numero de Póliza" :valid="validate.validations.policy_civil_insurer" v-model="entity.policy_civil_insurer" />
                  </div>
                </Fieldset>
                <br />
                <Fieldset :toggleable="true" legend="Información Aseguradora Medio Ambiente">
                  <div class="p-fluid formgrid grid">
                    <FormInputText wrapperClass="field col-4" label="Nombre" :valid="validate.validations.environment_insurer" v-model="entity.environment_insurer" />
                    <FormInputText wrapperClass="field col-4" label="Numero de Póliza" :valid="validate.validations.policy_environment_insurer" v-model="entity.policy_environment_insurer" />
                  </div>
                </Fieldset>
                <br />
                <Fieldset :toggleable="true" legend="Información Aseguradora Carga Transportada">
                  <div class="p-fluid formgrid grid">
                    <FormInputText wrapperClass="field col-4" label="Nombre" :valid="validate.validations.burden_insurer" v-model="entity.burden_insurer" />
                    <FormInputText wrapperClass="field col-4" label="Numero de Póliza" :valid="validate.validations.policy_burden" v-model="entity.policy_burden" />
                  </div>
                </Fieldset>
              </TabPanel>
            </TabView>
            <BasicDatatable :rows="entities" :headers="headers" :rowaction="true" 
        :rowdelete="true" :rowedit="true" @deleted="confirmDelete" @edited="edit" />
        <DeleteDialog v-model="deleteDialog" @closed="deleteDialog = false" @deleted="deleted" />
            </div>
          </div>
        </Panel>
      </div>
    </div>
  </div>
</template>

<script>
import FormDropdownComplex from "../../../components/general/FormDropdownComplex.vue";
import FormInputText from "../../../components/general/FormInputText.vue";
import FormInputNumber from "../../../components/general/FormInputNumber.vue";
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import BasicFormToolbar from "../../../components/general/BasicFormToolbar.vue";
import {HeaderGrid, Rule, validate, fillObject, Toast, ErrorToast, satCatalogo,} from "../../../utilities/General";
import Loader from "../../../components/general/Loader.vue";
import Session from "../../../mixins/sessionMixin";
import { ConsignmentNoteVehicleControl } from '../../../models/cfdi40/ConsingmentNoteVehicleControl';
import DeleteDialog from "../../../components/general/DeleteDialog.vue";
export default {
  mixins: [Session],
  props: {
    modal: null,
    id: null
  },
  data() {
    return {
      entity: null,
      entities: [],
      c_TipoPermiso: [],
      c_ConfigAutotransporte: [],
      deleteDialog: false,
      rules: [
      new Rule({ name: "vehicle_config_sat_code" }),
        new Rule({ name: "license_plate_vm" }),
        new Rule({ name: "anio_vm" }),
        new Rule({ name: "civil_insurer" }),
        new Rule({ name: "policy_civil_insurer" }),
        new Rule({ name: "permit_sct_sat" }),
        new Rule({ name: "num_permit_sct" }),
      ],
      validate: {
        valid: false,
        validations: {
          vehicle_config_sat_code: null,
          license_plate_vm: null,
          anio_vm: null,
          civil_insurer: null,
          policy_civil_insurer: null,
          permit_sct_sat: null,
          num_permit_sct: null,
        },
      },
      headers: [
        new HeaderGrid("Código Configuración", "vehicle_config_sat_code"),
        new HeaderGrid("Licencia", "license_plate_vm"),
        new HeaderGrid("Aseguradora Civil", "civil_insurer"),
        new HeaderGrid("Poliza Civil", "policy_civil_insurer"),
        new HeaderGrid("Permiso SCT", "permit_sct_sat"),
        new HeaderGrid("Num Permiso", "num_permit_sct"),
        new HeaderGrid("Año", "anio_vm"),
      ],
      loading: false,
    };
  },
  components: { FormDropdownComplex, Loader, FormInputText, FormInputNumber, BasicFormToolbar, BasicDatatable, DeleteDialog },
  created() {
    this.entity = new ConsignmentNoteVehicleControl(this.session);
  },
  async mounted() {
    await this.refresh();
    this.c_TipoPermiso = await satCatalogo(22);
    this.c_ConfigAutotransporte = await satCatalogo(23);
   },   
  methods: {
    deleted() {
        this.deleteEntity();
    },
    openNew() {
      this.entity = new ConsignmentNoteVehicleControl(this.session);
    },
    async save() {
      try {
        //* Validacion de form
        this.loading = true;
       this.validate = validate(this.entity, this.rules);
        if (!this.validate.valid) {
          throw "Favor de validar los campos";
        }              
        //* Si el id es != 0 entonces actualizamos, si no, guardamos
        if (this.entity.id && this.entity.id > 0) {
          //* Actualizamos
          let entity = await this.entity.update();
          this.$toast.add({
            severity: "success",
            summary: "Actualizar",
            detail: "Informacion actualizada con exito",
            life: 3000,
          });
          this.$emit('update', entity);
          //* Modificamos el listado pah
          let index = this.entities.findIndex((x) => x.id == this.entity.id);
          this.entities[index] = entity;
        } else {
          //* Creamos uno nuevo
          let entity = await this.entity.save();
          //* Agregamos un dato extra
          this.$emit('save', entity);
          this.entities.push(entity);
          this.$toast.add(
            new Toast({
              summary: "Creacion",
              detail: "Informacion guardada con exito",
            })
          );
        }
        this.entity = new ConsignmentNoteVehicleControl(this.session);
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    edit(entity) {
      this.entity = fillObject(this.entity, entity);
    },
    confirmDelete(entity) {
      this.entity = fillObject(this.entity, entity);
      this.deleteDialog = true;
    },
    async deleteEntity() {
      try {
        this.loading = true;
        //* Eliminamos de la base
        await this.entity.delete();
        //* Eliminamos de la vista
        this.entities = this.entities.filter((val) => val.id !== this.entity.id);
        this.deleteDialog = false;
        //* Limpiamos la entidad
        this.entity = new ConsignmentNoteVehicleControl(this.session);
        this.$toast.add({
          severity: "success",
          summary: "Eliminacion",
          detail: "Registro eliminado con exito",
          life: 3000,
        });
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },

    async list() {
        this.loading = true;
        try {
          this.entity.branch = this.session.branch;
          this.entity.company = this.session.company;
          this.entities = await new ConsignmentNoteVehicleControl().data({
            branch: this.session.branch,
            company: this.session.company,
            vehicle_config_sat_code: this.entity.vehicle_config_sat_code,
            license_plate_vm: this.entity.license_plate_vm,
            civil_insurer: this.entity.civil_insurer,
            policy_civil_insurer: this.entity.policy_civil_insurer,
            anio_vm: this.entity.anio_vm,
            permit_sct_sat: this.entity.permit_sct_sat,
            num_permit_sct: this.entity.num_permit_sct
          });
        } catch (error) {
          this.$toast.add(new ErrorToast(error));
        } finally {
          this.loading = false;
        }
      },

    async refresh() {
      this.loading = true;
      try {
        if (this.id) {
          this.entity.id = this.id;
          let entity = await this.entity.retrieve();
          this.entity = fillObject(this.entity, entity);
        }
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
  }
};
</script>

<style scoped lang="scss">
.table-header {
  display: flex;
  justify-content: space-between;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}

</style>
